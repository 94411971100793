import React from "react"

import "../style/bulma.css"
import "../style/coolharbour-tech.css"
import "../style/coolharbour-gentext.css"
import "../style/coolharbour-tech-internal-livelab.css"


import Layout from "../components/layout"
import Auditoraggregatorinternalbanner from "../components/internalpages/auditor-aggregator/auditoraggregatorinternalbanner"

import Contactform from "../components/homepage/contactform"
import SEO from "../components/seo"
import Coolfooter from "../components/coolfooter"

const Auditoragg = () => (
  <Layout>
    <SEO title="Auditor Aggregator" />
    <Auditoraggregatorinternalbanner />
   <Contactform />
   <Coolfooter />
  </Layout>
)

export default Auditoragg

