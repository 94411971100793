import React, { Component } from 'react';
import Gentablet from "../../../images/screenshots/auditor-agg-1.jpg";
import Waterback from "../../../images/auditbanner.jpg";

const styles = {
    backbanneraud: {
        backgroundImage: `url(${Waterback})`
    },
	backscreen: {
        backgroundImage: `url(${Gentablet})`
    }
};

class Auditoraggregatorinternalbanner extends Component {
    render() {

        return (
            <div>
                <section className="internalbanner" style={styles.backbanneraud}>
	<div className="overfunk"></div>
	<div className="container">
		<div className="columns is-marginless">
			<div className="column is-half">
				<p className="topsubtitle">Search for auditors</p>
				<h1 className="midsize">Auditor Aggregator Portal</h1>
				<p className="undersubtitle">Enable clients and suppliers to centralise and manage auditing services.</p>
			</div>
		</div>
	</div>
</section>
<section className="under-internalbanner graddown">
	<div className="threeboxes">
		<div className="container">
			<div className="boxcard norise">
				<div className="columns is-marginless">
					<div className="column is-10">

						<h2>Search for auditors in according to area, date, industry and budget</h2><hr/>
						<p>The web-based application portal enables the clients and suppliers to centralise and manage auditing services.</p><br/>
					</div>

				</div>
			</div>
		</div>
        </div>
		</section>
		<section className="largerpicsplit graddown">
	<div className="columns is-marginless">
					<div className="column is-4 is-offset-2 pictext">
					<h3>Centralised auditor management</h3>
						<p className="bigpee">The portal is designed to : </p>
						<ul>
						<li><span className="highlightlistspan">Search</span> for auditors in according to area, date, industry and budget</li>
	<li><span className="highlightlistspan">Allow</span> bookings to be placed</li>
	<li><span className="highlightlistspan">Allow</span> payments to be made</li>
	<li><span className="highlightlistspan">Upload</span> of accreditations of auditors</li>
	<li><span className="highlightlistspan">Reporting</span> of invoices sent by auditors</li>
	<li><span className="highlightlistspan">Reporting</span> of budget spent by clients</li>
	<li><span className="highlightlistspan">Scoring</span> mechanism to rank and rate auditors</li>
						</ul>
					</div>
					<div className="column is-5 backcover"  style={styles.backscreen}>
							<img src={Gentablet} className="hideo"/>

					</div>
				</div>
	</section>
            </div>
        );
    }
}

export default Auditoraggregatorinternalbanner;